import React, { useEffect, useMemo, useState } from "react";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { Plus, X } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { TransactionDocumentEmptyCard } from "@/feature/transactions/components/TransactionDocumentEmptyCard";
import { BankTransaction } from "@/models/bankTransaction";
import { useDocumentFilterQuery } from "@/api/endpoints/documentApi";

import { DocumentPreview } from "@/feature/documents/components/Card/CardFooter/DocumentPreview";
import { BiDocument } from "@/models/document";
import { matchingApi } from "@/api/endpoints/matchingApi";

interface TransactionDocumentHandlerProps {
  transaction: BankTransaction;
  onChangeDocument?: (document: BiDocument | null) => void;
  onSelectedDocument?: BiDocument | null | undefined;
}

export const TransactionDocumentTabs = ({
  transaction,
  onChangeDocument,
  onSelectedDocument,
}: TransactionDocumentHandlerProps) => {
  const documentIds = useMemo(() => {
    return transaction.match?.documents.map((d) => d.documentId) || [];
  }, [transaction]);

  const { data: documentData } = useDocumentFilterQuery({
    kind: "oneof",
    attribute: "id",
    value: documentIds,
  });

  const [selectedDocument, setSelectedDocument] = useState<BiDocument | null>(
    null,
  );
  const [showAddDocument, setShowAddDocument] = useState<boolean>(false);

  useEffect(() => {
    if (documentData && documentData.items.length > 0 && !selectedDocument) {
      setSelectedDocument(documentData.items[0]);
    } else {
      if (selectedDocument && !documentIds.includes(selectedDocument?.id))
        setSelectedDocument(null);
    }
  }, [documentData, documentIds, selectedDocument]);

  useEffect(() => {
    setShowAddDocument(false);
  }, [transaction]);

  const [deleteMatch] = matchingApi.useDeleteMatchMutation();
  const [createMatch] = matchingApi.useCreateMatchMutation();

  const remove = async () => {
    if (transaction.match) {
      await deleteMatch(transaction.match).unwrap();
    }

    let newDocumentIds = documentIds.filter(
      (id) => id !== selectedDocument?.id,
    );

    if (newDocumentIds.length > 0) {
      createMatch({
        transactionIds: [transaction.id],
        documentIds: [...newDocumentIds],
      }).then(() => {});
    }
  };

  return (
    <VerticalFlex gap={2}>
      <span className="text-xs text-foreground/50">Zugeordnete Dokumente</span>
      {transaction.match && selectedDocument && (
        <Tabs defaultValue={selectedDocument.id}>
          <HorizontalFlex gap={2} className="w-full">
            <TabsList
              className={`${showAddDocument ? "blur-[2px]" : ""} w-full hover:blur-none`}
            >
              {documentData?.items.map((docs) => (
                <TabsTrigger
                  key={docs.id}
                  value={docs.id}
                  className="flex-grow"
                  onClick={() => {
                    setSelectedDocument(docs);
                    setShowAddDocument(false);
                    if (onChangeDocument) {
                      if (onSelectedDocument) onChangeDocument(docs);
                    }
                  }}
                >
                  <HorizontalFlex
                    justify={"center"}
                    align={"center"}
                    className={"relative w-full"}
                  >
                    {docs.invoiceNumber}
                    {selectedDocument?.id === docs.id && (
                      <HorizontalFlex
                        title={"Dokument entfernen"}
                        className="m-0 p-0 opacity-50"
                      >
                        <X
                          onClick={() => {
                            remove().then();
                            if (onChangeDocument) onChangeDocument(null);
                          }}
                          className={
                            "absolute bottom-0 right-1 top-1/2 -translate-y-1/2 transform hover:text-red-500"
                          }
                          size={14}
                        />
                      </HorizontalFlex>
                    )}
                  </HorizontalFlex>
                </TabsTrigger>
              ))}
            </TabsList>
            <Button
              title={!showAddDocument ? "Dokument hinzufügen" : "Abbrechen"}
              className={`${showAddDocument ? "bg-foreground/10 hover:bg-red-500 hover:text-background" : ""}`}
              variant="ghost"
              onClick={() => {
                setSelectedDocument(null);
                setShowAddDocument(!showAddDocument);
              }}
            >
              {!showAddDocument && <Plus size={20} />}
              {showAddDocument && <X size={16} />}
            </Button>
          </HorizontalFlex>

          {documentData?.items.map((docs) => (
            <TabsContent key={docs.id} value={docs.id}>
              {showAddDocument ? (
                <TransactionDocumentEmptyCard
                  transaction={transaction}
                  document={docs}
                />
              ) : (
                <DocumentPreview
                  onChangeDocument={onChangeDocument}
                  document={docs}
                  onSelectedDocument={onSelectedDocument}
                />
              )}
            </TabsContent>
          ))}
        </Tabs>
      )}
    </VerticalFlex>
  );
};
