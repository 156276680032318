import { useTranslation } from "react-i18next";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "@/components/ui/date-range-picker";
import { Button } from "@/components/ui/button";
import { DateRange } from "react-day-picker";
import { GLOBAL_API_TOKEN } from "@/api/reduxApi";
import Spinner from "@/components/loading/spinner";
import { formatDate } from "@/util/format";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import { useDebounce } from "@/components/time/UseDebounce";
import { exportDatevAPI } from "@/api/endpoints/exportDatevApi";
import { simpleDateFrom, simpleDateToSimpleIso } from "@/models/document";

interface ExportDatevFormProps {
  close: () => void;
  onValueChange?: (dateRange: DateRange, status: string) => void;
  exportType: "export" | "bookings";
  validate: boolean;
}

export const ExportDatevForm = ({
  close,
  onValueChange,
  exportType,
  validate,
}: ExportDatevFormProps) => {
  const { t } = useTranslation();

  const [dateRange, setDateRange] = useState<DateRange | undefined>();

  const [datevValidationTrigger, datevValidationTriggerResponse] =
    exportDatevAPI.useLazyGetCheckDatevValidationQuery();

  const [showInvalidContent, setshowInvalidContent] = useState<boolean>(false);
  const [shouldClose, setShouldClose] = useState(false);
  const debouncedShouldClose = useDebounce(shouldClose, 500);
  useEffect(() => {
    if (debouncedShouldClose) {
      close();
    }
  }, [debouncedShouldClose]);

  const performDownload = () => {
    if (!dateRange) return;

    const hiddenElement = document.createElement("a");
    hiddenElement.href =
      (process.env.REACT_APP_API_URL ?? "") +
      `datev/${exportType}?token=` +
      encodeURIComponent(GLOBAL_API_TOKEN ?? "") +
      "&from=" +
      encodeURIComponent(
        dateRange.from
          ? simpleDateToSimpleIso(simpleDateFrom(dateRange.from))
          : "",
      ) +
      "&to=" +
      encodeURIComponent(
        dateRange.to ? simpleDateToSimpleIso(simpleDateFrom(dateRange.to)) : "",
      );
    hiddenElement.target = "_blank";
    hiddenElement.click();
    setShouldClose(true);
  };

  const handleDownload = () => {
    if (dateRange && dateRange.from && dateRange.to) {
      if (validate) {
        datevValidationTrigger({
          from: simpleDateToSimpleIso(simpleDateFrom(dateRange.from)),
          to: simpleDateToSimpleIso(simpleDateFrom(dateRange.to)),
        }).then((res) => {
          if (res.data && res.data.success) {
            performDownload();
          } else {
            setshowInvalidContent(true);
          }
        });
      } else {
        performDownload();
      }
    }
  };

  return (
    <>
      {!showInvalidContent && !datevValidationTriggerResponse.isLoading && (
        <VerticalFlex align="center">
          <Separator />
          <HorizontalFlex className={"w-full"} justify={"center"}>
            <DateRangePicker
              onUpdate={({ range }) => setDateRange(range)}
              preset={"month"}
              align={"center"}
              showCompare={false}
            ></DateRangePicker>
          </HorizontalFlex>
          <Button
            disabled={!dateRange}
            title={t("component.documents.modal.button.download")}
            variant={"outline"}
            className={"flex items-center justify-center"}
            onClick={() => {
              handleDownload();
            }}
          >
            <span>{t("common.download")}</span>
          </Button>
        </VerticalFlex>
      )}
      {showInvalidContent && !datevValidationTriggerResponse.isLoading && (
        <VerticalFlex align="center" gap={8}>
          <Separator />
          <HorizontalFlex
            className={"text-md w-full font-semibold"}
            justify={"center"}
          >
            <span>{formatDate(dateRange?.from?.toISOString()) ?? "-"}</span>
            <span className={"text-foreground/30"}>-</span>
            <span>{formatDate(dateRange?.to?.toISOString()) ?? "-"}</span>
          </HorizontalFlex>
          <VerticalFlex className={"text-sm"}>
            {datevValidationTriggerResponse.data?.documents?.length === 1 && (
              <span className={"font-thin"}>
                {t("component.documents.modal.hint_one")}
              </span>
            )}
            {datevValidationTriggerResponse.data &&
              datevValidationTriggerResponse.data.documents?.length > 1 && (
                <span className={"font-thin"}>
                  {t("component.documents.modal.hint_more")}
                </span>
              )}

            <ul className={"ml-8 list-disc"}>
              {datevValidationTriggerResponse.data?.documents
                ?.slice(0, 3)
                .map((doc, i) => (
                  <li key={doc.id + ":" + i}>
                    {/*@Viktor please use translations*/}
                    <HorizontalFlex align={"center"}>
                      <div>Rechnung</div>
                      <div>
                        {doc.invoiceNumber || (
                          <Skeleton className={"mt-1 h-[8px] w-8"} />
                        )}
                      </div>
                      <div>hochgeladen</div>
                      <div>{formatDate(doc.createdAt)}</div>
                    </HorizontalFlex>
                  </li>
                ))}
            </ul>
            {datevValidationTriggerResponse.data?.documents?.length === 4 && (
              <span className={"text-sm text-foreground/70"}>
                ...und ein weiteres
              </span>
            )}
            {datevValidationTriggerResponse.data &&
              datevValidationTriggerResponse.data.documents?.length > 4 && (
                <span className={"text-sm text-foreground/70"}>
                  ...und{" "}
                  {
                    +(
                      datevValidationTriggerResponse.data &&
                      datevValidationTriggerResponse.data.documents?.length - 3
                    )
                  }{" "}
                  weitere
                </span>
              )}
          </VerticalFlex>
          <Separator />
          <Button
            title={t("component.documents.modal.button.complete")}
            variant={"default"}
            className={"flex items-center justify-center"}
            onClick={() => {
              if (onValueChange && dateRange) {
                onValueChange(dateRange, "status_invalid");
              }
              close();
            }}
          >
            <span>{t("common.complete")}</span>
          </Button>
        </VerticalFlex>
      )}
      {datevValidationTriggerResponse.isLoading && (
        <VerticalFlex>
          <Spinner />
        </VerticalFlex>
      )}
    </>
  );
};
