import React, { useEffect, useState } from "react";
import { DateRangePicker } from "@/components/ui/date-range-picker";
import {
  DataTable,
  DataTableColumn,
  Sort,
} from "@/components/display/DataTable";
import { useTranslation } from "react-i18next";
import { Title } from "@/components/text/Title";
import { DateRange } from "react-day-picker";
import {
  DataTableMultiselect,
  SelectGroup,
} from "@/components/display/DataTableMultiselect";
import {
  Filter,
  FilterAnd,
  FilterEq,
  FilterInner,
  FilterNot,
  FilterParameters,
} from "@/api/types";
import {
  DataTablePagination,
  DataTablePaginationElement,
} from "@/components/display/DataTablePagination";
import Export from "./components/Export";
import { TransactionCard } from "@/feature/transactions/components/TransactionCard";
import { cn } from "@/lib/utils";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import {
  useBankAccountsFilterQuery,
  useBankTransactionFilterQuery,
} from "@/api/endpoints/bankApi";
import { BankTransaction } from "@/models/bankTransaction";
import { Checkbox } from "@/components/ui/checkbox";
import { formatMoney, formatSimpleDate } from "@/util/format";
import { CoinsIcon } from "lucide-react";
import Spinner from "@/components/loading/spinner";
import { Path } from "@/util/DeepProps";
import { CheckedState } from "@radix-ui/react-menu";
import { EmptyCard } from "@/feature/settings/BankSettings/components/EmptyCard";
import { Separator } from "@/components/ui/separator";
import { BookingBadge } from "@/feature/documents/components/BookingBadge";
import { useScreenDimensions } from "@/components/layout/screenWidth";
import BigDocumentPreview from "@/feature/documents/components/Document/BigDocumentPreview";
import {
  BiDocument,
  simpleDateFrom,
  simpleDateToSimpleIso,
} from "@/models/document";

const TransactionsContent = () => {
  const { t } = useTranslation();
  const [screenWidth] = useScreenDimensions();

  const { data: bankAccounts } = useBankAccountsFilterQuery({});

  const [filter, setFilter] = useState<FilterInner<BankTransaction>>({});
  const [sort, setSort] = useState<Sort<Path<BankTransaction>>>(null);

  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const [filterParams, setFilterParams] =
    useState<FilterParameters<BankTransaction>>();

  const [defaultSelected, setDefaultSelected] = useState<CheckedState>(false);
  const [selectionInversion, setSelectionInversion] = useState<string[]>([]);

  const [selectedTransactionId, setSelectedTransactionId] = useState<
    string | undefined
  >();

  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  useEffect(() => {
    setSelectionInversion([]);
  }, [defaultSelected]);

  let filterQuery: Filter<BankTransaction> = {
    sort: sort ? sort[0] : ("bookingDate" as any), // TODO Fix me a later date
    direction: sort ? sort[1] : "desc",
    ...filterParams,
    ...filter,
  };

  const { data, isLoading } = useBankTransactionFilterQuery(filterQuery);

  let selectedTransaction = data?.items.find(
    (i) => i.id === selectedTransactionId,
  );

  const paginationRef =
    React.createRef<DataTablePaginationElement<BankTransaction>>();

  const transactionColumns: DataTableColumn<BankTransaction>[] = [
    {
      header: (
        <HorizontalFlex gap={4} align={"center"}>
          <Checkbox
            checked={defaultSelected}
            onClick={(e) => e.stopPropagation()}
            onCheckedChange={setDefaultSelected}
          />
          {t("model.transaction.bookingDate")}
        </HorizontalFlex>
      ),
      sortKey: "bookingDate",
      cell: (item) => (
        <HorizontalFlex gap={4} align={"center"} className={"text-nowrap"}>
          <VerticalFlex
            gap={0}
            onClick={(e) => e.stopPropagation()}
            style={{
              // prevent drawer from opening on mis-clicks
              margin: "-1rem",
              padding: "1rem",
            }}
          >
            <Checkbox
              checked={defaultSelected !== selectionInversion.includes(item.id)}
              onCheckedChange={() => {
                if (selectionInversion.includes(item.id)) {
                  setSelectionInversion((prev) =>
                    prev.filter((i) => i !== item.id),
                  );
                } else {
                  setSelectionInversion((prev) => [...prev, item.id]);
                }
              }}
            />
          </VerticalFlex>
          {formatSimpleDate(item.bookingDate)}
        </HorizontalFlex>
      ),
    },
    {
      header: t("model.transaction.bankAccount"),
      sortKey: "bankAccountId",
      cell: (item) => {
        return (
          <VerticalFlex gap={0}>
            <span className={"font-semibold"}>
              {item.bankAccount?.productName}
              {item.bankAccount?.productName && ","}{" "}
              {item.bankAccount?.bankName}
            </span>
            <span className={"text-sm opacity-50"}>{item.accountIBAN}</span>
          </VerticalFlex>
        );
      },
    },
    {
      header: t("model.transaction.transactionAmount"),
      sortKey: "transactionAmount",
      cell: (item) => (
        <HorizontalFlex justify={"between"}>
          <CoinsIcon
            className={cn(
              item.transactionAmount < 0 ? "text-red-500" : "text-green-500",
            )}
          />
          {formatMoney(item.transactionAmount)}
        </HorizontalFlex>
      ),
    },
    {
      header: t("model.transaction.otherPartyName"),
      cell: (item) => (
        <VerticalFlex gap={0}>
          <span className={"font-semibold"}>{item.otherPartyName}</span>
          <span className={"text-sm opacity-50"}>{item.otherPartyIBAN}</span>
        </VerticalFlex>
      ),
    },
    {
      header: t("model.transaction.remittanceInformation"),
      cell: (item) => {
        let lines = item.remittanceInformation.split("\n");
        return (
          <div className={"max-w-xl"} style={{ overflowWrap: "anywhere" }}>
            {lines.join(", ")}
          </div>
        );
      },
    },
    {
      header: t("model.transaction.status"),
      cell: (item) => (
        <HorizontalFlex justify={"end"}>
          <BookingBadge item={item} />
        </HorizontalFlex>
      ),
    },
  ];

  let selectGroups: SelectGroup<BankTransaction>[] = [
    {
      key: "bankAccountId",
      label: t("model.transaction.bankAccount"),
      values: (bankAccounts?.items || []).map((b) => ({
        label: b.productName + ", " + b.bankName,
        value: b.id,
      })),
    },
    {
      key: "match",
      label: t("model.transaction.status"),
      values: [
        {
          label: t("model.transaction.status_open"),
          value: "status_open",
          filter: FilterEq("matches.id" as any, null),
        },
        {
          label: t("model.transaction.status_booked"),
          value: "status_booked",
          filter: FilterEq("matches.amountOpen" as any, 0),
        },
        {
          label: t("model.transaction.status_partial"),
          value: "status_partial",
          filter: FilterAnd(
            FilterNot(FilterEq("matches.amountOpen" as any, 0)),
            FilterNot(FilterEq("matches.id" as any, null)),
          ),
        },
      ],
    },
  ];

  const [selectedDocument, setSelectedDocument] = useState<BiDocument | null>(
    null,
  );

  const onChangeDocument = (document: BiDocument | null) => {
    setSelectedDocument(document);
  };

  return (
    <VerticalFlex gap={8}>
      {bankAccounts?.total === 0 && (
        <VerticalFlex>
          <EmptyCard />
          <Separator orientation="horizontal" />
        </VerticalFlex>
      )}
      <Title>{t("component.transactions.main.title")}</Title>
      <HorizontalFlex align={"center"}>
        <DataTableMultiselect<BankTransaction>
          additionalFilters={
            dateRange
              ? [
                  {
                    kind: "range",
                    attribute: "bookingDate",
                    lower: dateRange.from
                      ? simpleDateToSimpleIso(simpleDateFrom(dateRange.from))
                      : undefined,
                    upper: dateRange.to
                      ? simpleDateToSimpleIso(simpleDateFrom(dateRange.to))
                      : undefined,
                  },
                ]
              : undefined
          }
          textSearchKeys={[
            "mandateId",
            "checkId",
            "otherPartyName",
            "otherPartyIBAN",
            "remittanceInformation",
          ]}
          selectGroups={selectGroups}
          placeholder={t(
            "ui.multipleSelector.transaction.table.searchPlaceholder",
          )}
          onFilterChange={setFilter}
        />
        <DateRangePicker
          initialSelected={false}
          onUpdate={({ range }) => setDateRange(range)}
          align="start"
          locale="de-DE"
          showCompare={false}
        />
        <Separator orientation={"vertical"} />
        <Export
          filterQuery={filterQuery}
          defaultSelected={!!defaultSelected}
          selectionInversion={selectionInversion}
        />
      </HorizontalFlex>

      <HorizontalFlex gap={2}>
        <VerticalFlex
          gap={8}
          tabIndex={0}
          className={
            "relative flex-[2] grow overflow-y-scroll transition-all duration-300"
          }
        >
          {isLoading && <Spinner />}
          {!isLoading && (
            <DataTable<BankTransaction>
              rowBreak={
                screenWidth < 1400 ? [(i) => i < 3, (i) => i >= 3] : undefined
              }
              onSortChange={setSort}
              columns={transactionColumns}
              onClick={(t) => {
                setSelectedTransactionId(t.id);
                setShowDrawer(
                  t.id === selectedTransactionId ? !showDrawer : true,
                );
              }}
              data={data?.items ?? []}
              selected={
                showDrawer && selectedTransactionId
                  ? [selectedTransactionId]
                  : []
              }
            />
          )}
          <DataTablePagination
            ref={paginationRef}
            onChange={setFilterParams}
            defaultPageSize={10}
            result={data}
          />
          <VerticalFlex className={"absolute left-0 top-0 w-full"}>
            {selectedDocument && (
              <BigDocumentPreview document={selectedDocument as BiDocument} />
            )}
          </VerticalFlex>
        </VerticalFlex>
        <VerticalFlex
          style={{
            flexBasis: "600px",
            minWidth: "300px",
          }}
          className={cn(
            "transition-shadow duration-300 hover:shadow-lg",
            showDrawer ? "flex" : "hidden",
          )}
        >
          {selectedTransaction && (
            <TransactionCard
              onChangeDocument={onChangeDocument}
              transaction={selectedTransaction}
              onSelectedDocument={selectedDocument}
              onClose={() => setShowDrawer(false)}
            />
          )}
        </VerticalFlex>
      </HorizontalFlex>
    </VerticalFlex>
  );
};

export default TransactionsContent;
